<template>
  <div class="LogAdmin todo">
    <div class="page-header flex">
      <span style="font-size:16px;">日志管理</span>
    </div>
    <div style="padding: 20px;">
      <div class="screen-list flex">
        <div class="screen-list-item flex">
          <span class="screen-item-label">查询条件</span>
          <a-input
            placeholder="请输入关键字"
            style="flex: 1;height: 36px;"
            v-model="Keyword"
          />
        </div>

        <div class="screen-list-item flex">
          <div class="flex" style="flex:1;margin-right:20px;">
            <span class="screen-item-label">类型</span>
            <a-tree-select
              style="flex:1"
              :treeData="classifyList"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allowClear
              treeDefaultExpandAll
              @change="_classify"
            ></a-tree-select>
          </div>
          <div class="flex" style="flex:1">
            <span class="screen-item-label">创建人</span>
            <a-tree-select
              style="flex:1"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allowClear
              treeDefaultExpandAll
            ></a-tree-select>
          </div>
        </div>
        <div class="screen-list-item flex">
          <span class="screen-item-label">发生时间</span>
          <a-range-picker
            style="flex: 1"
            format="YYYY-MM-DD"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="_createdDate"
          />
        </div>
      </div>

      <div style="text-align: right;margin: 15px  0;padding: 5px 10px;">
        <a-button style="width: 110px;height: 34px;margin-right: 20px"
          >重置条件</a-button
        >
        <a-button
          type="primary"
          style="width: 110px;height: 34px;"
          @click="_searchBtn"
          >开始查询</a-button
        >
      </div>

      <div class="data-map" style="width: 100%">
        <div class="map-title">
          操作分析({{ infoData.startDate }}-{{ infoData.endDate }})
        </div>
        <div id="main" style="height:550px;margin: 0 auto"></div>
      </div>

      <div style="margin-top: 20px;">
        <a-table
          :columns="columns"
          :dataSource="infoData.data"
          :pagination="false"
          bordered
          v-if="infoData.data"
        ></a-table>
      </div>

      <div style="margin: 20px 0;text-align: left">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="pageTotal"
          @change="_PageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogAdmin',
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          key: 'sNumber',
          align: 'center',
          width: 80
        },
        {
          title: '类型',
          dataIndex: 'Type',
          key: 'Type',
          align: 'center',
          width: 300
        },
        {
          title: '所属分类',
          dataIndex: 'CategoryName',
          key: 'CategoryName',
          align: 'center',
          width: 180
        },
        {
          title: '操作人',
          dataIndex: 'UserName',
          key: 'UserName',
          align: 'center',
          width: 180
        },
        {
          title: '发生时间',
          dataIndex: 'CreateTime',
          key: 'CreateTime',
          align: 'center',
          width: 200
        },
        { title: '备注', dataIndex: 'Content', key: 'Content' }
      ],
      infoData: [],
      classifyList: [],
      pie: '',
      pageTotal: 0,
      startEndDate: '',
      Keyword: '',
      Type: '',
      CategoryId: '',
      startDate: '',
      endDate: ''
    }
  },
  created () {
    this.$axios.get(1215, { pid: 'b7cdca0fa782755ccd958ef095fd335a' }, res => {
      if (res.data.code == 1) {
        this.classifyList = res.data.data
      }
    })
  },
  mounted () {
    this._info(1)
  },
  methods: {
    _PageChange (pageNumber) {
      this.page = pageNumber
      this._info(pageNumber)
    },
    _info (page) {
      let self = this
      this.$axios.get(
        1214,
        {
          Keyword: this.Keyword,
          Type: '',
          CategoryId: this.CategoryId,
          UserId: '',
          startDate: this.startDate,
          endDate: this.endDate,
          Page: page
        },
        res => {
          if (res.data.code == 1) {
            this.pageTotal = res.data.Count
            this.infoData = res.data
            this.pie = res.data.ChartData
          } else {
            this.pageTotal = 0
            this.infoData = []
            this.pie = ''
          }
          let echarts = require('echarts')
          document.getElementById(
            'main'
          ).style.width = document.getElementsByClassName(
            'data-map'
          )[0].offsetWidth
          let myChart = echarts.init(document.getElementById('main'))
          let option = {
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: self.pie.xAxis ? self.pie.xAxis : [],
              axisLine: {
                lineStyle: {
                  color: '#aaa',
                  width: 1 //这里是为了突出显示加上的
                }
              }
            },
            grid: [
              {
                left: '5%',
                right: '5%'
              }
            ],
            yAxis: {
              type: 'value',
              splitLine: {
                //分割线
                show: true,
                lineStyle: {
                  color: '#cbcec6',
                  type: 'dotted'
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#aaa',
                  width: 1 //这里是为了突出显示加上的
                }
              }
            },
            series: [
              {
                data: self.pie.yAxis ? self.pie.yAxis : [],
                type: 'line',
                smooth: false,
                areaStyle: {
                  normal: {
                    //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: 'rgba(80,141,255,0.39)'
                      },
                      {
                        offset: 0.34,
                        color: 'rgba(56,155,255,0.25)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(38,197,254,0.00)'
                      }
                    ])
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#1890FF', //改变折线点的颜色
                    lineStyle: {
                      color: '#319CFF' //改变折线颜色
                    }
                  }
                }
              }
            ]
          }
          myChart.setOption(option, true)
          window.addEventListener('resize', function () {
            myChart.resize
          })
        }
      )
    },
    _searchBtn () {
      this._info(1)
    },
    _classify (value) {
      this.CategoryId = value
    },
    _createdDate (value) {
      this.startEndDate = value
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
    }
  }
}
</script>

<style scoped lang="less">
.LogAdmin {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;
  .page-header {
    height: 55px;
    line-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e2e3;
  }
  .screen-list {
    margin-top: 10px;
    .screen-list-item {
      margin-right: 20px;
      flex: 1;
    }
    .screen-item-label {
      margin-right: 10px;
      color: #666;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .data-map {
    padding: 20px;
    height: 600px;
    border: 1px solid #f7f7f7;
    .map-title {
      color: #666666;
      font-size: 16px;
    }
  }
}
</style>
